import { swiperInstance } from "../swiper";

let timer;

const returnX = (item) => {
  return item.offsetTop - (window.innerHeight / 2 - item.offsetHeight / 2);
};

function SmoothVerticalScrolling(e, time, where) {
  var eTop = e.getBoundingClientRect().top;
  var eAmt = eTop / 100;
  var curTime = 0;
  while (curTime <= time) {
    window.setTimeout(SVS_B, curTime, eAmt, where);
    curTime += time / 100;
  }
}

function SVS_B(eAmt, where) {
  if (where == "center" || where == "") window.scrollBy(0, eAmt / 2);
  if (where == "top") window.scrollBy(0, eAmt);
}

export const networkScroll = () => {
  const slideshows = [...document.querySelectorAll(".slideshow__swiper")];
  const participants = [...document.querySelectorAll(".participant")];
  const practiceList = document.querySelector(".practice__list");
  const slidesshowList = document.querySelector(".practice__gallery");

  const participantOnMouseEnter = (e) => {
    const participant = e.target.closest(".participant");
    const index = participant.dataset.index;
    slideshows.forEach((slideshow) => {
      if (slideshow.classList.contains("is-active")) {
        slideshow.classList.remove("is-active");
      }
    });
    participant.classList.add("is-active");
    practiceList.classList.add("is-hover");
    slidesshowList.classList.add("is-hover");
    slideshows[index].classList.add("is-active");
    timer = setTimeout(() => {
      document
        .querySelector(".content--right")
        .parentNode.scrollTo(0, returnX(slideshows[index]));
    }, 500);
  };
  const participantOnMouseLeave = (e) => {
    const participant = e.target.closest(".participant");
    if (participant) {
      const index = participant.dataset.index;
      slideshows[index].classList.remove("is-active");
      participant.classList.remove("is-active");
    }
    practiceList.classList.remove("is-hover");
    slidesshowList.classList.remove("is-hover");
    clearTimeout(timer);
  };

  const slideshowOnMouseEnter = (e) => {
    const slideshow = e.target.closest(".slideshow__swiper");
    const index = slideshow.dataset.index;
    participants.forEach((participant) => {
      if (participant.classList.contains("is-active")) {
        participant.classList.remove("is-active");
      }
    });
    slideshow.classList.add("is-active");
    practiceList.classList.add("is-hover");
    slidesshowList.classList.add("is-hover");
    participants[index].classList.add("is-active");
    timer = setTimeout(() => {
      document
        .querySelector(".content--left")
        .parentNode.scrollTo(0, returnX(participants[index]));
    }, 500);
  };
  const slideshowOnMouseLeave = (e) => {
    const slideshow = e.target.closest(".slideshow__swiper");
    if (slideshow) {
      const index = slideshow.dataset.index;
      participants[index].classList.remove("is-active");
      slideshow.classList.remove("is-active");
    }
    practiceList.classList.remove("is-hover");
    slidesshowList.classList.remove("is-hover");
    clearTimeout(timer);
  };

  const onCaptionClick = (e) => {
    e.preventDefault();
    swiperInstance.slideTo(0);
    slideshowOnMouseEnter(e);
  };
  const onParticipantClick = (e) => {
    e.preventDefault();
    swiperInstance.slideTo(2);
    participantOnMouseEnter(e);
  };

  participants.forEach((participant) => {
    if (window.innerWidth > 768) {
      participant.addEventListener("mouseenter", participantOnMouseEnter);
      participant.addEventListener("mouseleave", participantOnMouseLeave);
    } else {
      participant
        .querySelector("h2")
        .addEventListener("click", onParticipantClick);
      document
        .querySelector(".practice__list")
        .addEventListener("touchmove", participantOnMouseLeave);
    }
  });
  slideshows.forEach((slideshow) => {
    if (window.innerWidth > 768) {
      slideshow.addEventListener("mouseenter", slideshowOnMouseEnter);
      slideshow.addEventListener("mouseleave", slideshowOnMouseLeave);
    } else {
      slideshow
        .querySelector(".slideshow__caption__btn")
        .addEventListener("click", onCaptionClick);
      document
        .querySelector(".practice__gallery")
        .addEventListener("touchmove", slideshowOnMouseLeave);
    }
  });
};
