import Swiper from "swiper";
import { createObserver } from "./utils";

const breakpoint = window.matchMedia("(min-width:768px)");
const slideshows = [];
let timer;

export const checkSlideshow = () => {
  if (breakpoint.matches === false) {
    return slideShowMobileInit();
  } else if (breakpoint.matches === true) {
    return slideShowInit();
  }
};

const slideShowInit = () => {
  [...document.querySelectorAll(".slideshow__swiper")].forEach(
    (slideshow, index) => {
      slideshows[index] = new Swiper(slideshow, {
        speed: 400,
        spaceBetween: 16,
        preloadImages: false,
        autoHeight: true,
        lazy: true,
        on: {
          lazyImageReady: function (slideEl, imageEl) {
            imageEl.classList.add("show");
          },
        },
      });

      slideshow.querySelectorAll(".swiper-slide").forEach((slide) => {
        slide.addEventListener("click", (e) => {
          e.preventDefault();
          !slideshows[index].isEnd && !slideshows[index].isAnimating
            ? slideshows[index].slideNext()
            : slideshows[index].slideTo(0);
        });
      });
      // slideshow.style.display = "none";
    }
  );

  if (document.querySelector(".content--right")) {
    document
      .querySelector(".content--right")
      .parentNode.addEventListener("scroll", (e) => {
        clearTimeout(timer);
      });
  }
};

const observer = createObserver(IntersectionObserver);
const loadImageOnScroll = (e) => {
  if (e.isIntersecting) {
    const slide = e.target;
    const image = slide.querySelector("img");
    const hiRes = new Image();
    hiRes.src = image.dataset.src;
    hiRes.onload = () => {
      image.src = hiRes.src;
      setTimeout(() => {
        image.classList.add("show");
        slide.querySelector(".swiper-lazy-preloader") !== null &&
          slide.querySelector(".swiper-lazy-preloader").remove();
      }, 500);
    };
  }
};

const slideShowMobileInit = () => {
  [...document.querySelectorAll(".slideshow__swiper")].forEach(
    (slideshow, index) => {
      slideshow
        .querySelector(".swiper-wrapper")
        .classList.remove("swiper-wrapper");
      slideshow.querySelectorAll(".swiper-slide").forEach((slide) => {
        observer.observe(slide, loadImageOnScroll);
      });
    }
  );
};
