import Hammer from 'hammerjs';

var hammertime = new Hammer(document.querySelector('.list'));

hammertime.get('swipe').set({ direction: Hammer.DIRECTION_HORIZONTAL, threshold: 5 });
hammertime.on('swipeleft', function (ev) {
  const swiper = document.querySelector('#swiperInstance').swiper;
  swiper.slideNext();
});
hammertime.on('swiperight', function (ev) {
  const swiper = document.querySelector('#swiperInstance').swiper;
  swiper.slidePrev();
});