import Swiper from 'swiper';

const breakpoint = window.matchMedia('(min-width:768px)');
const listWrapper = document.querySelector('.list__wrapper');
export let swiperInstance;


export const destroySwiper = () => {
  if (breakpoint.matches === false) {
    [...document.querySelectorAll('.content')].forEach(content => {
      content.style.display = 'none';
    })
  }
  if (swiperInstance !== undefined) { swiperInstance.destroy(false, false); }
}

export const createSwiper = (namespace) => {
  if (breakpoint.matches === true) {
    if (swiperInstance !== undefined) { swiperInstance.destroy(true, true); }
  } else if (breakpoint.matches === false) {
    setTimeout(() => {
      [...document.querySelectorAll('.content')].forEach(content => {
        content.style.display = 'block';
      })
      return enableSwiper(namespace);
    }, 0);
  }
};

let currentIndex = 0 ;
const enableSwiper = function (namespace) {
  swiperInstance = new Swiper('#swiperInstance', {
    a11y: true,
    slidesPerView: 'auto',
    centeredSlides: true,
    simulateTouch: true,
    speed: 500,
    keyboard: {
      enable: true
    },
    on: {
      init: function(){
        namespace === 'network' ||
        namespace === 'search' ||
        namespace === 'practice'
          ? swiperMobileUnlock(this)
          : swiperMobileLock(this)
      },
      slideChangeTransitionStart: function(){
        const progressMiddle = this.progress - 0.5;
        const middle = window.innerWidth * 1.5;
        listWrapper.style.transition = `transform 500ms`;
        listWrapper.style.transform = `translateX(${middle * (progressMiddle * -1)}px)`;
      },
      touchMove: function (e){
        const progressMiddle = this.progress - 0.5;
        const middle = (window.innerWidth * 1.5);
        listWrapper.style.transition = `transform 0ms`;
        listWrapper.style.transform = `translateX(${middle * (progressMiddle * -1)}px)`;
        currentIndex = this.activeIndex;
      },
      transitionStart: function(){
        if(this.activeIndex == currentIndex){
          let endPoint;
          switch (this.activeIndex) {
            case 0:
              endPoint = window.innerWidth;
              break;
            case 1:
              endPoint = 0;
              break;
            case 2:
              endPoint = window.innerWidth * -1;
              break;
            default:
              break;
          }
          listWrapper.style.transition = `transform 500ms`;
          listWrapper.style.transform = `translateX(${endPoint}px)`;
        }
      }
    }
  });
};

export const swiperMobileUnlock = (swiper) => {
  listWrapper.style.display = 'flex'
  swiper.allowTouchMove = true
  swiper.slideTo(1,0)
}

export const swiperMobileLock = (swiper) => {
  listWrapper.style.display = 'none'
  swiper.slideTo(0,0)
  swiper.allowTouchMove = false
}

breakpoint.addListener(createSwiper);