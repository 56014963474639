import { async } from 'q';
import Fuse from 'fuse.js';

let renderedList;
let chosenWords = [];
let chosenWordsText = [];

const autoCompleteInit = async () => {
  const options = {
    shouldSort: true,
    threshold: 0.6,
    location: 0,
    distance: 100,
    maxPatternLength: 32,
    minMatchCharLength: 1,
    keys: [
      "title",
      "author.firstName"
    ]
  };
  const form = document.getElementById('words');
  const result = await fetch(form.dataset.json);
  const json = await result.json();
  const fuse = new Fuse(json['Words'], options);

  form.addEventListener('input', e => {
    var result = fuse.search(form.value);
    const topResults = result.slice(0,5);
    renderResults(topResults, json['Words']);
  })


  form.addEventListener('keydown', e => {
    if (e.keyCode == 13) {
      renderedList.querySelector('.join__word:first-of-type').click();
      return false;
    }
  })

}


const renderResults = (results, json) => {
  const form = document.getElementById('words');
  renderedList !== undefined ? renderedList.remove() : null;
  renderedList = document.createElement('ul');
  results.forEach((result, index) => {
    const listItem = document.createElement('li');
    listItem.classList.add('join__word');
    listItem.dataset.index = index;
    listItem.innerText = result.item;
    listItem.addEventListener('click', resultClickBehaviour)
    if (!chosenWordsText.includes(listItem.innerText)) {
      renderedList.appendChild(listItem);
    }
  })
  form.parentNode.appendChild(renderedList);
}

const resultClickBehaviour = e => {
  e.preventDefault();
  const word = e.target;
  if (chosenWords.length !== 5) {    
    chosenWords.push(word);
    chosenWordsText.push(word.innerText.toLowerCase());
    document.querySelector('.join__chosen-words').appendChild(word);
    renderedList !== undefined ? renderedList.remove() : null;
    document.getElementById('words').value = '';
    word.addEventListener('click', chosenWordClickBehaviour);
  } 
  if (chosenWords.length === 5) {
    document.getElementById('words').style.display = 'none';
  }
  document.getElementById('wordsChosenHidden').value = chosenWordsText;
}

  const chosenWordClickBehaviour = e => {
    e.preventDefault();
    const word = e.target;
    chosenWords = chosenWords.filter(e => e !== word);
    chosenWordsText = chosenWordsText.filter(e => e !== word.innerText.toLowerCase());
    word.remove();
    document.getElementById('words').style.display = 'block';
    document.getElementById('wordsChosenHidden').value = chosenWordsText;

}


export const formInit = namespace => {
  if (namespace == 'join') {
    autoCompleteInit();
    var inputs = document.querySelectorAll('.join__file');
    const form = document.getElementById('joinForm');
    form.addEventListener('keydown', e => {
      if (event.keyCode == 13) {
        event.preventDefault();
        return false;
      }
    })



    Array.prototype.forEach.call(inputs, function (input) {
      inputs.forEach(input => {
        const closeBtn = input.parentNode.querySelector('.join__file__remove-btn');
        const label = input.parentNode.querySelector('.join__file-label');
        closeBtn.addEventListener('click', e => {
          e.preventDefault();
          input.value = "";
          label.innerText = "+";
          closeBtn.classList.remove('is-active');
        })
      })
    
      input.addEventListener('change', function (e) {
        const image = input.parentNode.querySelector('.join__file-label');
        const closeBtn = input.parentNode.querySelector('.join__file__remove-btn');
        image.innerText = '';
        if (input.files && input.files[0]) {
          var reader = new FileReader();
          reader.onload = function (e) {
            const newImage = new Image();
            newImage.src = e.target.result;
            image.appendChild(newImage);
            closeBtn.classList.add('is-active');
          };
          reader.readAsDataURL(input.files[0]);
        }
      });
    });
  }
}

