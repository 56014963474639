export const transition__intro = namespace => {
  if (namespace == "home") {
    document.getElementById('loader').style.opacity = 0;
    return new Promise(resolve => {
      setTimeout(() => {
        document.getElementById('loader').remove();
        document.querySelector('.header__modus-logo').classList.add('loaded');
        document.body.classList.remove('page--transitioning');
        resolve();
      }, 2000);
    });
  } else {
    document.querySelector('.header__modus-logo').classList.add('loaded');
    document.body.classList.remove('page--transitioning');
  }
}