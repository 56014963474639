import barba from '@barba/core';
import regeneratorRuntime from 'regenerator-runtime'
import barbaRouter from '@barba/router';
import queryString from 'query-string';
import { listRelease, listMoveTo } from './list';
import { networkScroll } from './network/network';
import './navigation';
import './gestures';
import { formInit } from './form';
import './utils';
import { checkSlideshow } from './slideshow';
import { transition__intro } from './transitions';
import { destroySwiper, createSwiper, swiperMobileLock, swiperMobileUnlock} from './swiper';

const isMobile = window.innerWidth < 768;

window.onload = () => {
  transition__intro(document.querySelector('.main').dataset.barbaNamespace)
  createSwiper(document.querySelector('.main').dataset.barbaNamespace);
  formInit(document.querySelector('.main').dataset.barbaNamespace);
  checkSlideshow();
  if (document.querySelector('.main').dataset.barbaNamespace === 'network' ||
      document.querySelector('.main').dataset.barbaNamespace === 'practice' ||
      document.querySelector('.main').dataset.barbaNamespace === 'search') {
      networkScroll()
  }
}

export const Barba = barba.init({
  debug: false,
  transitions: [{
    appear: data => {
    },
    leave: () => {
      document.body.classList.add('page--transitioning');
      return new Promise(resolve => {
        setTimeout(() => {
          resolve();
        }, 125);
      })
    },
    beforeEnter: data => {
      if (data.next.namespace !== 'practice') {
        listRelease();
      } else {
        const word = queryString.parse(window.location.search);
        listMoveTo(word.q);
      }
    },
    after: (data) => {
      destroySwiper();
      createSwiper(data.next.namespace);
      formInit(data.next.namespace);
      setTimeout(() => {
        checkSlideshow();
        document.body.classList.remove('page--transitioning');
        document.querySelector('.hamburger').classList.remove('is-active')
        document.body.classList.remove('nav-active')
        if (data.next.namespace === 'network' || 
            data.next.namespace === 'practice' ||
            data.next.namespace === 'search'
        ) { networkScroll() }
      }, 500);
    }
  }]
});

const reloadPage = () => { barba.force(window.location.href) }

const breakpoint = window.matchMedia('(min-width:768px)');
breakpoint.addListener(reloadPage);
