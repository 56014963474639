import 'intersection-observer-polyfill';

var links = document.querySelectorAll('a[href]');
var cbk = function (e) {
  if (e.currentTarget.href === window.location.href) {
    e.preventDefault();
    e.stopPropagation();
  }
};

for (var i = 0; i < links.length; i++) {
  links[i].addEventListener('click', cbk);
}

const setVH = () => {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
};

setVH();

export const createObserver = (Observer, options) => {
  var observer = new Observer(entries => entries.forEach(handle), options)
  var callbacks = new Map();
  var handle = entry => {
    var cbs = callbacks.get(entry.target);
    if (cbs) cbs.forEach(cb => cb(entry));
  }
  var observe = (el, cb) => {
    if (callbacks.has(el)) {
      callbacks.set(el, [...callbacks.get(el), cb]);
    } else {
      callbacks.set(el, [cb]);
      observer.observe(el);
    }
  }
  var unobserve = (el, cb) => {
    var cbs = callbacks.get(el);
    if (cbs) {
      cbs = cbs.filter(x => x !== cb);
      if (cbs.length) {
        callbacks.set(el, cbs);
      } else {
        callbacks.delete(el);
        observer.unobserve(el);
      }
    }
  }
  return { observe, unobserve };
}