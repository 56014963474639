const list = document.querySelector('.list');
const itemHeight = list.querySelector('li:first-of-type').offsetHeight;
const listItemArray = [...list.querySelectorAll('li')];
const practiceURL = document.querySelector('.list__contents').dataset.practice;
let fullListArray;


let requestId = undefined;
let scrollY = (itemHeight * listItemArray.length);
let scrollTimer;
let hoverTimer;
let scrollAmount = 1;
let framesPerSecond = 60;

let listRunning = true;


window.requestAnimationFrame = function () {
  return window.requestAnimationFrame ||
    window.webkitRequestAnimationFrame ||
    window.mozRequestAnimationFrame ||
    window.msRequestAnimationFrame ||
    window.oRequestAnimationFrame ||
    function (f) {
      window.setTimeout(f, 1e3 / 60);
    }
}();


const roundUp = (x, n) => {
  return Math.round(x / n) * n;
}

export const listMoveTo = word => {
  if (!word) return;
  listRunning = false; // Stop list running.

  const itemsAboveViewportPrev = Math.ceil(scrollY / itemHeight);
  const wordsInView = Math.ceil(window.innerHeight / itemHeight);
  const currentlySelected = list.querySelector('.item--item-selected');
  const wordsOnScreen = fullListArray.slice(itemsAboveViewportPrev, itemsAboveViewportPrev + wordsInView);
  let item;

  wordsOnScreen.forEach(visibleWord => {
    const visibleWordText = visibleWord.innerText.toLowerCase();
    if (visibleWordText === word) {
      return item = visibleWord;
    }
  });

  if(item == null) {
    fullListArray.forEach(visibleWord => {
      const visibleWordText = visibleWord.innerText.toLowerCase();
      if (visibleWordText === word) {
        return item = visibleWord;
      }
    });
  }

  if (currentlySelected != null) { currentlySelected.classList.remove('item--item-selected') }
  list.classList.add('list--item-selected');
  item.classList.add('item--item-selected');
  const scrollTo = (item.dataset.index * itemHeight) - ((window.innerHeight / 2) - (itemHeight));
  list.scroll({
    top: scrollTo,
    left: 0,
    behavior: 'smooth'
  });

}

var limitLoop = function (fn, fps) {
  var then = new Date().getTime();
  fps = fps || 60;
  var interval = 1000 / fps;

  return (function loop(time) {
    requestAnimationFrame(loop);
    var now = new Date().getTime();
    var delta = now - then;

    if (delta > interval) {
      then = now - (delta % interval);
      fn();
    }
  }(0));
};

export const listRelease = () => {
  scrollY = list.scrollTop;
  const currentlySelected = list.querySelector('.item--item-selected');
  if (currentlySelected != null) { currentlySelected.classList.remove('item--item-selected') }
  list.classList.remove('list--item-selected');
  setTimeout(() => {
    listRunning = true; // Start list running again.
  }, 500);
}

const newList = () => {
  fullListArray = [...list.querySelectorAll('li')]; // Register cloned items.
  fullListArray.forEach( (item, index) => {
    item.dataset.index = index;
    item.querySelector('a').addEventListener('click', e => {
      // list_onClick(item);
    })
  })
}

const renderList = () => {
  const visibleItems = Math.ceil(window.innerHeight / itemHeight);
  const scrollYNew = list.scrollHeight - scrollY;
  const offset = list.offsetHeight - scrollYNew;

  if (Math.abs(offset) < itemHeight * visibleItems ) {
    const listContentsNext = list.querySelector('ul').cloneNode(true);
    document.querySelector('.list__contents').appendChild(listContentsNext);
    if (list.querySelectorAll('ul').length > 2) {
      list.querySelector('ul:first-of-type').remove();
    }
    newList();
    scrollY = list.scrollTop;
  }

  if (scrollY <= itemHeight * visibleItems) {
    const listContentsPrev = list.querySelector('ul').cloneNode(true);
    document.querySelector('.list__contents').prepend(listContentsPrev);
    if (list.querySelectorAll('ul').length > 2) {
      list.querySelector('ul:last-of-type').remove();
    }
    // newList();
    scrollY = list.scrollTop;
  }
}

const scrollList = () => {
  scrollY += scrollAmount;
  // if (scrollY % itemHeight == 0) {
  list.scrollTop = scrollY;
  renderList();
  // }
}

const animateList = () => {
  requestId = undefined;
  startList();
  if(listRunning){
    scrollList();
  }
}

const startList = () => {
  if (!requestId) {
    requestId = window.requestAnimationFrame(animateList);
    // limitLoop(animateList, 30);
  }
}

const stopList = () => {
  if (requestId) {
    window.cancelAnimationFrame(requestId);
    requestId = undefined;
  }
}

list.addEventListener('wheel', e => {
  scrollY = list.scrollTop;
  stopList();
  renderList();
  if (scrollTimer) {
    clearTimeout(scrollTimer);
    scrollTimer = null
  }
  scrollTimer = setTimeout(() => {
    scrollY = roundUp(list.scrollTop, scrollAmount);
    startList();
  }, 2000);
});


list.addEventListener('touchstart', e => {
  stopList();
});


list.addEventListener('touchmove', e => {
  scrollY = list.scrollTop;
  stopList();
  renderList();
  if (scrollTimer) {
    clearTimeout(scrollTimer);
    scrollTimer = null
  }
  scrollTimer = setTimeout(() => {
    scrollY = roundUp(list.scrollTop, scrollAmount);
    startList();
  }, 5000);
});

list.addEventListener('mouseenter', e => {
  hoverTimer = setTimeout(() => {
    scrollAmount = 0;
  }, 100);
})
list.addEventListener('mouseleave', e => {
  if (hoverTimer) {
    clearTimeout(hoverTimer);
    scrollAmount = 1;
  }
})

const initList = () => {
  listItemArray.forEach(item => {
    const word = item.innerText;
    const anchor = document.createElement('a');
    anchor.innerText = word;
    anchor.href = `${practiceURL}?q=${word.toLowerCase()}`;
    item.innerText = '';
    item.appendChild(anchor);
  });

  const listClone = list.querySelector('ul').cloneNode(true);
  list.querySelector('.list__contents').appendChild(listClone);
  newList();
  startList()
  list.scrollTop = scrollY;
  list.classList.add('is-active');
}

initList();


