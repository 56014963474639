import disableScroll from 'disable-scroll';

const navigationButton = document.querySelector('.hamburger');
const mobileNav = document.querySelector('.mobile-header__menu .navigation');

navigationButton.addEventListener('click', e => {
  e.preventDefault()
  navigationButton.classList.toggle('is-active');
  document.body.classList.toggle('nav-active');
  const swiper = document.querySelector('#swiperInstance').swiper;
  // swiper.slideTo(1);
  document.body.classList.contains('nav-active') ? disableScroll.on() : disableScroll.off();
})

mobileNav.querySelectorAll('a').forEach(link => {
  link.addEventListener('click', e => {
    e.preventDefault();
    mobileNav.querySelectorAll('li').forEach(list => {
      list.classList.remove('active');
    })
    link.parentNode.classList.add('active');
    disableScroll.off();
  })
})

document.querySelectorAll('.navigation a').forEach(link => {
  link.addEventListener('click', e => {
    document.querySelectorAll('.navigation li').forEach(link => {link.classList.remove('active')})
    link.parentNode.classList.add('active')
  })
})